export const RESET_HOUSING_PERIOD_STATE = "RESET_HOUSING_PERIOD_STATE";
export const SET_SEARCH_HOUSING_PERIODS_RESULTS = "SET_SEARCH_HOUSING_PERIODS_RESULTS";
export const SET_HOUSING_PERIOD_BASIC_DETAILS = "SET_HOUSING_PERIOD_BASIC_DETAILS";
export const CLEAR_HOUSING_PERIOD = "CLEAR_HOUSING_PERIOD";
export const DELETE_HOUSING_PERIOD = "DELETE_HOUSING_PERIOD";
export const DELETE_HOUSING_PERIOD_SUCCESS = "DELETE_HOUSING_PERIOD_SUCCESS";
export const DELETE_HOUSING_PERIOD_ERROR = "DELETE_HOUSING_PERIOD_ERROR";
export const ENABLE_DISABLE_HOUSING_PERIOD = "ENABLE_DISABLE_HOUSING_PERIOD";
export const ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS = "ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS";
export const ENABLE_DISABLE_HOUSING_PERIOD_ERROR = "ENABLE_DISABLE_HOUSING_PERIOD_ERROR";
export const LOAD_HOUSING_PERIOD_SELECT_ITEMS = "LOAD_HOUSING_PERIOD_SELECT_ITEMS";
export const LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS = "LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS";
export const LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR = "LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR";
export const SEARCH_HOUSING_PERIODS = "SEARCH_HOUSING_PERIODS";
export const SEARCH_HOUSING_PERIODS_SUCCESS = "SEARCH_HOUSING_PERIODS_SUCCESS";
export const SEARCH_HOUSING_PERIODS_ERROR = "SEARCH_HOUSING_PERIODS_ERROR";
export const LOAD_HOUSING_PERIOD = "LOAD_HOUSING_PERIOD";
export const LOAD_HOUSING_PERIOD_SUCCESS = "LOAD_HOUSING_PERIOD_SUCCESS";
export const LOAD_HOUSING_PERIOD_ERROR = "LOAD_HOUSING_PERIOD_ERROR";
export const INITIALIZE_NEW_HOUSING_PERIOD = "INITIALIZE_NEW_HOUSING_PERIOD";
export const SAVE_HOUSING_PERIOD_BASIC_DETAILS = "SAVE_HOUSING_PERIOD_BASIC_DETAILS";
export const SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS = "SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS";
export const SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR = "SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR";
export const SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP = "SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP";
export const INITIALIZE_HOUSING_PERIOD_PROPERTIES = "INITIALIZE_HOUSING_PERIOD_PROPERTIES";
export const INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS = "INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS";
export const INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR = "INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR";
export const GET_PROPERTIES_FOR_HOUSING_PERIOD = "GET_PROPERTIES_FOR_HOUSING_PERIOD";
export const GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS = "GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS";
export const GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR = "GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR";

// Actions for the Manage Properties > Edit Housing Period Property page
export const LOAD_HOUSING_PERIOD_PROPERTY = "LOAD_HOUSING_PERIOD_PROPERTY";
export const LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_ERROR";
export const CLEAR_HOUSING_PERIOD_PROPERTY = "CLEAR_HOUSING_PERIOD_PROPERTY";
export const SAVE_HOUSING_PERIOD_PROPERTY = "SAVE_HOUSING_PERIOD_PROPERTY";
export const SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS = "SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS";
export const SAVE_HOUSING_PERIOD_PROPERTY_ERROR = "SAVE_HOUSING_PERIOD_PROPERTY_ERROR";

// Actions for the Manage Properties > Applications page
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR";
export const INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION = "INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR";
export const SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION = "SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION";
export const SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS = "SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS";
export const SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR = "SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR";
export const CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION = "CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION"